import React, { useState } from "react"
import isEqual from "lodash/isEqual"

import Hero from "./Hero"
import Featuring from "./Featuring"
import QuestionList from "../FAQ/QuestionList"
// import { Footer } from "@lesmills/gatsby-theme-common"

// import type { LayoutType } from "@lesmills/gatsby-theme-common"

import MembershipInfo from "./MembershipInfo"

type Props = {|
  data: Object,
  // layout: LayoutType,
  handleClickNo: () => void,
  handleShowNZOffer: () => void,
|}

const MemberDiscount = ({
  data,
  layout,
  handleClickNo,
  handleShowNZOffer,
}: Props) => {
  const [isShowMemberInfo, setIsShowMemberInfo] = useState(false)
  const { body = [] } = data || {}
  const [faq = {}, featuring = {}] = body
  // const [footer = {}] = layout.footer || {}
  const primary = faq.primary || {}
  const fields = faq.items || []
  const { les_mills_plus_logo } = layout || {}

  const faqData = {
    primary: {
      title: primary.faq_title,
    },
    items: fields.map(item => ({
      question: item.faq_question,
      answers: item.faq_answer,
    })),
  }

  return (
    <>
      <Hero
        data={data}
        logo={les_mills_plus_logo}
        handleClickYes={() => setIsShowMemberInfo(true)}
        isShowMemberInfo={isShowMemberInfo}
        handleClickNo={handleClickNo}
      />
      {isShowMemberInfo && (
        <MembershipInfo handleShowNZOffer={handleShowNZOffer} data={data} />
      )}
      <Featuring data={featuring} />
      <QuestionList
        data={faqData}
        noBackToTop
        classNames={{
          title:
            "uppercase md:text-6xl leading-32 md:leading-24 text-gray-800 font-primary",
          question:
            "uppercase font-primary md:text-3xl text-2lg text-white mb-0",
          answer: "text-base md:text-2lg text-white font-base-light",
        }}
      />
      {/* Included footer in membership page */}
      {/* <Footer lang={lang} navs={footer.fields} /> */}
    </>
  )
}

export default React.memo(MemberDiscount, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.handleClickNo, nextProps.handleClickNo)
  )
})
